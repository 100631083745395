<template>
  <v-row class="px-10" dense>
    <v-col cols="12" md="12">
      <v-text-field v-model="company.state_registration" label="Inscrição Estadual" placeholder="EX: 603.512.452.366"
        v-mask="['###.###.###.###']" outlined rounded dense />

    </v-col>
    <v-col cols="12" md="6">
      <v-text-field v-model="company.site" label="Site" outlined rounded dense />
    </v-col>
    <v-col cols="12" md="6">
      <v-text-field v-model="company.cnpj" label="CPF/CNPJ" v-mask="['##.###.###/####-##']"
        placeholder="xx.xxx.xxx/xxxx-xx" outlined rounded dense />
    </v-col>

    <v-col cols="12" md="12">
      <v-text-field v-model="company.note" label="Anotação ou Observação" placeholder="Digite alguma observação" outlined
        rounded dense />
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    company: Object,
  },
};
</script>